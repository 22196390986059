import * as React from "react";
import { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import axios from "../../api/axios";
import CouponsTable from "./components/coupons-table";
import { Button, IconButton, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import { EditTwoTone } from "@mui/icons-material";

export default function CouponList() {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    // for offline test
    // setCoupons([
    //   {
    //     id: 2192,
    //     code: "Layan2",
    //     discount: 50,
    //     type: "DV",
    //     usage_limit: 5,
    //     usage_count: 0,
    //     validity: "2023-08-31",
    //     dynamic_value: [
    //       {
    //         to: "300",
    //         from: "200",
    //         discountValue: "60",
    //       },
    //     ],
    //     package_types: ["BD"],
    //     package_durations: [26],
    //     is_active: true,
    //   },
    //   {
    //     id: 2191,
    //     code: "Layan1",
    //     discount: 20,
    //     type: "PR",
    //     usage_limit: 5,
    //     usage_count: 0,
    //     validity: "2023-08-31",
    //     dynamic_value: [],
    //     package_types: ["LF", "SC"],
    //     package_durations: [12, 26],
    //     is_active: true,
    //   },
    //   {
    //     id: 2190,
    //     code: "Layan",
    //     discount: 10,
    //     type: "FV",
    //     usage_limit: 5,
    //     usage_count: 0,
    //     validity: "2023-08-31",
    //     dynamic_value: [],
    //     package_types: ["BD", "SC"],
    //     package_durations: [12],
    //     is_active: true,
    //   },
    // ]);
    try {
      axios.get("coupon/list/").then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response.data);
          setCoupons(response.data);
        } else {
          console.log("Something went wrong");
        }

        setLoading(false);
      });
    } catch (err) {
      console.log(err);

      setLoading(false);
    }
  }, []);

  const EditButton = ({ row }) => (
    <IconButton
      onClick={() => {
        navigate("/create-coupon", {
          state: row,
        });
      }}
    >
      <EditTwoTone color="primary" />
    </IconButton>
  );
  const body = (
    <>
      <div>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            onClick={() => {
              navigate("/create-coupon");
            }}
            color="primary"
            variant="contained"
            sx={{ mb: 2, alignSelf: "start" }}
          >
            Create Coupon
          </Button>
          <TextField
            value={search}
            label="Search"
            onInput={(e) => {
              setSearch(e.target.value);
            }}
            sx={{ mb: 4, width: "50%" }}
            InputProps={{
              endAdornment: search !== "" && (
                <IconButton
                  onClick={() => {
                    setSearch("");
                  }}
                >
                  <ClearTwoToneIcon />
                </IconButton>
              ),
            }}
          ></TextField>
          <CouponsTable
            rows={coupons.filter((c) => {
              return c.code.toLowerCase().includes(search.toLowerCase());
            })}
            loading={loading}
            ActionButton={EditButton}
          ></CouponsTable>
        </Box>
      </div>
    </>
  );

  return (
    <>
      <Layout body={body} title={"Coupons"} />
    </>
  );
}
