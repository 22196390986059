import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import CustomTable from "../../../components/customTable";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EditTwoTone } from "@mui/icons-material";

function EventDishCategoryList() {
    const navigate = useNavigate();
    const [eventDishCategories, setEventDishCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get("event/dish_category/list/")
            .then((response) => {
                setEventDishCategories(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                console.error(error.response.data);
                setLoading(false);
            });
    }, []);

    const headCells = [
        {
            id: "display_name",
            numeric: false,
            disablePadding: true,
            label: "Name"
        },
        {
            id: "event",
            numeric: false,
            disablePadding: true,
            label: "Event"
        },
        {
            id: "is_carb_changable",
            numeric: false,
            disablePadding: true,
            label: "Is Carb Changable"
        },
        {
            id: "price",
            numeric: false,
            disablePadding: true,
            label: "Price"
        },
        {
            id: "minimum_order",
            numeric: false,
            disablePadding: true,
            label: "Minimum Order"
        },
        {
            id: "action",
            numeric: false,
            label: "Action",
            noLoop: true,
            disablePadding: true,
        }
    ];

    const EditButton = ({ row }) => (
        <IconButton
            onClick={() => {
                navigate("/create-event-dish-category", {
                    state: row,
                });
            }}
        >
            <EditTwoTone color="primary" />
        </IconButton>
    )

    return (
        <div>
            <CustomTable
                rows={eventDishCategories}
                loading={loading}
                headCells={headCells}
                ActionButton={EditButton}
            ></CustomTable>
        </div>
    );
}

export default EventDishCategoryList;