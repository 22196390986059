import React, { useState } from "react";
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { TextField, Typography, Button, Snackbar, Alert } from "@mui/material";
import axios from "../../../api/axios";
import * as FileSaver from "file-saver";
function ChefList1100Calories() {
    const [date, setDate] = useState(dayjs());
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    
    async function downloadOrderListDetails() {

        try {
            setLoading(true);
            const response = await axios.post("order/1100calories/chef-list/", {
                date: dayjs(date).format('YYYY-MM-DD')
            },
                {
                responseType: 'blob'
            });
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                FileSaver.saveAs(blob, "chef_1100calories_" + dayjs(date).format('DD-MM-YYYY') + ".xlsx");
            }
            else {
                console.log(response.data.msg);
                setAlertOpen(true);
                setAlertMessage(response.data.msg);
                setAlertSeverity("error");
            }

        } catch (error) {
            console.error(error);
            setAlertOpen(true);
            setAlertMessage(error);
            setAlertSeverity("error");
        }
        finally {
            setLoading(false);
        }
    }
    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertOpen(false);
    };
    // Function to disable Fridays
    const shouldDisableDate = (date) => {
        return date.getDay() === 5; // 5 corresponds to Friday
    };

    const body = (
        <div>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Typography variant="h6">
                Download 1100 calories order list for kitchen:
            </Typography>
            
            <LocalizationProvider
                dateAdapter={AdapterDateFns}>
                <DatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    label="Date"
                    value={date}
                    onChange={(date) => setDate(date)}
                    shouldDisableDate={shouldDisableDate} 
                    fullWidth
                    renderInput={(props) => <TextField sx={{ mt: 2, width: 220 }}{...props} fullWidth />}
                />
            </LocalizationProvider>
            <div>

            </div>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => {
                    downloadOrderListDetails();
                }}
                disabled={loading}
            >
                Download
            </Button>
        </div>
    );
    return body;

}

export default ChefList1100Calories;