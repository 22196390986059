import * as React from "react";
import { useState } from "react";
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { TextField, Typography, Button, Snackbar, Alert } from "@mui/material";
import axios from "../../../api/axios";
import * as XLSX from "xlsx";
function OderListPerMonth() {
    const [date, setDate] = useState(dayjs());
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    async function oderListPerMonth() {

        try {
            setLoading(true);
            console.log(dayjs(date).format('YYYY-MM'));
            const response = await axios.post("get_orders_per_month/", {
                year: dayjs(date).year(),
                month: dayjs(date).month() + 1,
            });
            if (response.status === 200) {
                const fileType =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                
                const worksheet = XLSX.utils.json_to_sheet(response.data["data"]);

                // Create a new workbook, add the worksheet to it
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "data");

                // Write the workbook to a file and download it
                // make the name of the file based on the query params

                XLSX.writeFile(workbook, dayjs(date).format('YYYY_MM') +"_orders.xlsx");
                
                
                
                
            }
            else {
                console.log(response.data.msg);
                setAlertOpen(true);
                setAlertMessage(response.data.msg);
                setAlertSeverity("error");
            }

        } catch (error) {
            console.error(error);
            setAlertOpen(true);
            setAlertMessage(error);
            setAlertSeverity("error");
        }
        finally {
            setLoading(false);
        }
    }
    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertOpen(false);
    };

    const body = (
        <div>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Typography variant="h6">
                Download Item order count per month:
            </Typography>

            
            <LocalizationProvider
                dateAdapter={AdapterDateFns}>
                <DatePicker
                    views={["year", "month"]}
                    disableToolbar
                    variant="inline"
                    format="MM/YYYY"
                    label="Date"
                    value={date}
                    onChange={(date) => setDate(date)}
                    fullWidth
                    renderInput={(props) => <TextField sx={{ mt: 2, width: 220 }}{...props} fullWidth />}
                />
            </LocalizationProvider>
            <div>

            </div>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => {
                    oderListPerMonth();
                }}
                disabled={loading}
            >
                Download
            </Button>
        </div>
    );
    return body;

}

export default OderListPerMonth;