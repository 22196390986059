import * as React from "react";
import { useState, useEffect } from "react";
import axios from "../../../api/axios";
import dayjs from 'dayjs';
import CustomTable from "../../../components/customTable";
import { IconButton, Button, Select, MenuItem, InputLabel, FormControl, Grid, Typography, Checkbox, TextField, OutlinedInput } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EditTwoTone, ArrowBack } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import Layout from "../../../layout/layout";
import FileSaver from 'file-saver';
import * as XLSX from "xlsx";
import DishList from "../dish/dishListPage";

function ShowMenu() {
    const navigate = useNavigate();
    const [dishes, setDishes] = useState([]);
    const [loading, setLoading] = useState(false);
    const headCells = [
        {
            id: "photo",
            numeric: false,
            disablePadding: true,
            label: "Photo",
            noLoop: true,
        },
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
        },
        {
            id: "details",
            numeric: false,
            disablePadding: false,
            label: "Description",
        },
        {
            id: "calories",
            numeric: false,
            disablePadding: false,
            label: "Calories",
        }
    ];
    const [date, setDate] = useState(dayjs());



    const ImagePreview = ({ row }) => (
        <img src={process.env.REACT_APP_MEDIA_URL + row.photo} alt="preview" style={{ height: '100px' }} />
    );




    const handleDownloadDishes = () => {
        const dishesWithPhotoUrl = dishes.map(dish => ({
            ...dish,
            photo: process.env.REACT_APP_MEDIA_URL + dish.photo
        }));

        const ws = XLSX.utils.json_to_sheet(dishesWithPhotoUrl);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Menu');
        XLSX.writeFile(wb, `menu_${date}.xlsx`);
    };
    const handleDownloadMenu = async () => {
        setLoading(true);
        try {
            const response = await axios.post("menu/get/", {
                date: dayjs(date).format('YYYY-MM-DD')
            });
            if (response.status === 200) {
                console.log(response.data);
                setDishes(response.data);
                console.log(dishes);

            }
        } catch (error) {
            console.error("Error fetching dish list:", error);
        }
        setLoading(false);
    };

    const body = (

        <div>
            <Button variant="contained" component={Link} to="/meals-page"
                sx={{ mb: 2 }}> <ArrowBack /> Back to Meals</Button>

            <Button
                onClick={handleDownloadDishes}
                color="primary"
                variant="contained"
                sx={{ ml: 2, mb: 2, alignSelf: "start" }}
            >
                Download Menu
            </Button>

            <Grid container spacing={2} sx={{ mb: 2 }}>

                <Grid item xs={6}>
                    <Grid container direction="column" spacing={2}>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="date"
                                label=" Date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => setDate(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleDownloadMenu();
                                }}
                                disabled={loading}
                            >
                                Show Menu
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <CustomTable
                rows={dishes}
                loading={loading}
                headCells={headCells}
                ImagePreview={ImagePreview}

            ></CustomTable>
        </div>
    );

    return <Layout body={body} title={"Dishes"} />;
};

export default ShowMenu;
