import { Button } from "@mui/material";
import Layout from "../../../layout/layout";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import EventDishList from "./event-dish-list";

export default function EventDishesPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const body = (
        <div>
            <Button variant="contained" component={Link} to="/events"
                sx={{ mb: 2 }}><ArrowBack /> Back to Event </Button>
            <Button variant="contained" color="primary" sx={{ mr: 2, mb: 2, ml: 2 }}
                onClick={() => {
                    navigate("/create-event-dish", { replace: true, state: { event: location.state.event } });
                }}>
                Create Event Dish
            </Button>
            
            <p>{location.state.event.name} - Dish List</p>
            <EventDishList event={location.state.event} />

        </div>
    );
    return <Layout body={body} title={"Categories"} />;
}