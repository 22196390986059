import { Paper, Typography } from '@mui/material';
import React from 'react'

function DataCard({ title, data, footer }) {
    return (
        <Paper sx={{ p: 2 }}>
        <Typography variant="h6" color="primary">{title}</Typography>
            <Typography variant="h4" color="textPrimary">{data}</Typography>
            <Typography variant="p" color="textSecondary">{footer}</Typography>
    </Paper>);
}


export default DataCard