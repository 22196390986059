import * as React from "react";
import { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import axios from "../../api/axios";


import { TextField, Button, ToggleButtonGroup, ToggleButton, Snackbar, Alert, Grid, Card, Typography } from "@mui/material";
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { Link, useLocation } from 'react-router-dom';
import { set, parse } from "date-fns";

const minDate = dayjs().add(3, 'day');
function BuyEventSubscription() {
    const location = useLocation();
    const [userID, setUserID] = useState(0);
    const [breakfastCount, setBreakfastCount] = useState(0);
    const [mealCount, setMealCount] = useState(1);
    const [snackCount, setSnackCount] = useState(0);
    const [sweetCount, setSweetCount] = useState(0);
    const [proteinLevel, setProteinLevel] = useState();
    const [price, setPrice] = useState(0);
    const [duration, setDuration] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [userName, setUserName] = useState(null);
    const [isValidID, setIsValidID] = useState(false);
    // isvalidpackage
    const [isValidPackage, setIsValidPackage] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [dishCategories, setDishCategories] = useState([]);
    const [eventId, setEventId] = useState(location.state.event.id);
    const [proteinLevels, setProteinLevels] = useState([])
    const [event, setEvent] = useState(location.state.event);

    const eventName = location.state.event.name;


    useEffect(() => {
        try {
            axios
                .post("event/info/", { event_id: eventId })
                .then((response) => {

                    setDishCategories(response.data.event_dish_categories.map(category => ({
                        ...category,
                        count: category.minimum_order,
                    })));
                    setProteinLevels(response.data.event_protein_levels);
                    setProteinLevel(response.data.event_protein_levels[0].level);
                    setEvent(response.data.event);
                    console.log(response);
                })
                .catch((error) => {
                    console.error(error);
                    console.error(error.response.data);
                });
        }
        catch (error) {
            console.error(error);
            console.error(error.response.data);
        }
    }, [eventId]);

    useEffect(() => {
        // calculate price based on inputs
        
        var calculatedPrice = event.box_price;
        dishCategories.map(category => {
            calculatedPrice += category.price * category.count;
            return null; // Add a return statement here
        })
        calculatedPrice = Math.ceil(calculatedPrice * duration);
        setPrice(calculatedPrice);
    }, [duration,dishCategories,event]);

    useEffect(() => {
        // get username from user id
        getUsernameFromUserId(userID)
    }, [userID]);

    useEffect(() => {
        // convert all count to number
        console.log(dayjs(startDate).format('DD/MM/YYYY'))

        var temp = isValidID && duration > 0 && startDate !== null;
        temp = startDate <= event.start_date && temp;
        console.log(event.start_date)
        console.log(startDate >= event.start_date);
        dishCategories.map(category => {
            temp = temp && category.count >= category.minimum_order;
            return null; // Add a return statement here
        }
        )


        setIsValidPackage(temp);
    }, [
        isValidID,
        mealCount,
        duration,
        startDate,
        breakfastCount,
        snackCount,
        sweetCount,
        dishCategories,event
    ]);





    async function getUsernameFromUserId(userId) {
        try {
            const response = await axios.post("get_username_from_id/", {
                user_id: userId,
            });
            if (response.data.msg === "invalid user id") {
                setIsValidID(false);
                setUserName("");
            } else {
                setIsValidID(true);
                setUserName(response.data.msg);
            }

        } catch (error) {
            console.error(error);
            setIsValidID(false);
            setUserName("");
            return null;
        }
    }
    // createSubscription();
    async function createSubscription() {
        try {
            const response = await axios.post("event/subscription/create/", {
                user_id: userID,
                protein_level: proteinLevel,
                duration: duration,
                start_date: dayjs(startDate).format('YYYY-MM-DD'),
                event_dish_categories: dishCategories.map(category => ({
                    id: category.id,
                    count: category.count,
                })),
                event_id: event.id,

            });
            if (response.status === 201) {
                // setAlertOpen(true);
                console.log(response.data);
                // setAlertSeverity("success");
            } else {
                setAlertOpen(true);
                setAlertMessage(response.data.msg);
                setAlertSeverity("error");
            }

        } catch (error) {
            console.error(error);
            return null;
        }
    }
    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertOpen(false);
    };


    const body = (
        <>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <p>Buy package for {eventName} ,n,m</p>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label="User ID"
                        value={userID}
                        type="number"
                        onChange={(e) => setUserID(e.target.value)}
                    />

                    <> {(isValidID) ? "" : <h3 style={{ color: 'red' }}>Invalid User ID</h3>}
                        <h3 style={{ color: 'green' }}>{userName}</h3>
                    </>


                </Grid>
                <Grid item xs={6}>

                    <Card sx={{ p: 2, maxWidth: 350 }}>
                        <Typography variant="h4" color="primary">{price} KWD</Typography>
                        <Typography variant="p" color="textSecondary" >Breakfast: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{breakfastCount}</Typography>
                        <Typography variant="p" color="textSecondary">, Meal: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{mealCount}</Typography>
                        <Typography variant="p" color="textSecondary">, Snack: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{snackCount}</Typography>
                        <Typography variant="p" color="textSecondary">, Sweet: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{sweetCount}</Typography>
                        <br></br>
                        <Typography variant="p" color="textSecondary">Protein: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{proteinLevel}</Typography>
                        <Typography variant="p" color="textSecondary">, Duration: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{duration} days</Typography>
                        <br></br>
                        <Typography variant="p" color="textSecondary">Start Date: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{dayjs(startDate).format('YYYY-MM-DD')}</Typography>

                    </Card>

                </Grid>



                {
                    dishCategories.map((category, index) => {
                        return (
                            <Grid item xs={6} key={category.id}>
                                <TextField
                                    label={category.name + " ( Minimum " + category.minimum_order+ " )"}
                                    type="number"
                                    value={category.count}
                                    InputProps={{
                                        inputProps: { min: category.minimum_order }
                                    }}
                                    onChange={(e) => {
                                        const newCount = e.target.value;
                                        setDishCategories(prevCategories => {
                                            const newCategories = [...prevCategories];
                                            newCategories[index].count = newCount;
                                            return newCategories;
                                        });
                                    }}
                                />
                            </Grid>
                        )
                    })

                }



                <Grid item xs={6}>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}>
                        <DatePicker
                            disableToolbar
                            variant="inline"
                            format="DD/MM/YYYY"
                            label="Start Date"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            fullWidth
                            renderInput={(props) => <TextField sx={{ mt: 2, width: 220 }}{...props} fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <p>Protein level</p>
                    <ToggleButtonGroup
                        color="primary"
                        value={proteinLevel}
                        exclusive
                        onChange={(e) => setProteinLevel(Number(e.target.value))}
                        aria-label="Protein Level"
                    >
                        {proteinLevels.map((level) => (
                            <ToggleButton key={level.id} value={level.level}>{level.level}</ToggleButton>
                        ))}

                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={6}>
                        <TextField
                            label="Input Duration"
                            type="number"
                            value={duration}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            onChange={(e) => setDuration(e.target.value)}
                        />
                    </Grid>

                    {/* <ToggleButtonGroup
                        color="primary"
                        value={duration}
                        exclusive
                        onChange={(e) => setDuration(Number(e.target.value))}
                        aria-label="Select Duration"
                    >
                        {
                            event.durations.map((duration) => (
                                <ToggleButton key={duration} value={duration}>{duration}</ToggleButton>
                            ))
                        }
                    </ToggleButtonGroup> */}

                </Grid>


                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        disabled={!isValidPackage}
                        onClick={() => {
                            // call api to create subscription
                            createSubscription();
                        }}
                    >
                        Create Subscription
                    </Button>
                </Grid>
            </Grid>
        </>
    )

    return (
        <Layout
            body={
                body
            }
            title="Buy Subscription" />
    );
}


export default BuyEventSubscription;
