import { Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import axios from '../../../api/axios';

function PackagePerMonth() {
    const [data, setData] = React.useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(
                "package/count/month/",
            );
            setData(response.data.data);
        };
        fetchData();
    }, []);
    return (

        <><Paper sx={{ mt: 20, height: 500, p: 2, mb: 5 }}>
            <Typography variant='h6' color="primary" sx={{ mb: 2 }}> Package started per month</Typography>
            <ResponsiveContainer width="100%" height="90%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >

                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="lifestyle" stackId="a" fill="#8884d8" />
                    <Bar dataKey="bodybuilding" stackId="a" fill="#82ca9d" />
                    <Bar dataKey="scale" stackId="a" fill="#984447" />
                    <Bar dataKey="lunchbox" stackId="a" fill="#096B72" />
                    <Bar dataKey="1100Calories" stackId="a" fill="#FFA500" />
                    <Bar dataKey="1250Calories" stackId="a" fill="#FF0000" />
                </BarChart>
            </ResponsiveContainer>
        </Paper>
        </>
    )
}

export default PackagePerMonth