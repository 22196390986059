import axios from "axios";

const production = "https://scale-kuwait.com/admin_dashboard/";
const development = "http://127.0.0.1:8000/admin_dashboard/";
const testing = "https://rango.scale-kuwait.com/admin_dashboard/";
const runningserver = production;
export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
