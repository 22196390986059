import React from 'react'
import axios from '../../../api/axios';
import DataCard from '../../../components/dataCard'

function TotalUserCard() {
    const [totalUser, setTotalUser] = React.useState(0);
    const [genderText, setGenderText] = React.useState('');
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(
                "/user/count/",
            );
            setTotalUser(response.data.count);

        };


        const fethDataGender = async () => {
            const responseGender = await axios.get(
                "/user/count/gender/",
            );
            setGenderText('Male: ' + responseGender.data.male + ' Female: ' + responseGender.data.female);
        }

        try {
            fetchData();
            fethDataGender();
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <DataCard title="Total user" data={totalUser} footer={genderText} />
    )
}

export default TotalUserCard