import { Button } from "@mui/material";
import Layout from "../../layout/layout";
import { useNavigate } from "react-router-dom";
import EventList from "./event/event-list";

function EventsPage() {
    const navigate = useNavigate();
    const body = (
        <div>
            <Button variant="contained" color="primary" sx={{ mr: 2, mb: 2 }}
                onClick={() => {
                    navigate("/create-event", { replace: true });
                }}>
                Create Event
            </Button>
            
            <EventList />
        </div>
    );
    return <Layout body={body} title={"Events"} />;
}

export default EventsPage;