import * as React from "react";
import { useState, useEffect } from "react";
import axios from "../../../api/axios";
import CustomTable from "../../../components/customTable";
import { IconButton, Button, Select, MenuItem, InputLabel, FormControl, Grid, Typography, Checkbox, TextField, OutlinedInput } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EditTwoTone, ArrowBack } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import Layout from "../../../layout/layout";
import FileSaver from 'file-saver';
import * as XLSX from "xlsx";

function DishList() {
    const navigate = useNavigate();
    const [dishes, setDishes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredDishes, setFilteredDishes] = useState([]);
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [tags, setTags] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [isSelectFocused, setIsSelectFocused] = React.useState(false);
    const headCells = [
        {
            id: "photo",
            numeric: false,
            disablePadding: true,
            label: "Photo",
            noLoop: true,
        },
        {
            id: "id",
            numeric: false,
            disablePadding: true,
            label: "ID",
        },
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
        },
        {
            id: "category",
            numeric: false,
            disablePadding: false,
            label: "Category",
        },
        {
            id: "tags",
            numeric: false,
            disablePadding: false,
            label: "Tags",
        },
        {
            id: "action",
            numeric: false,
            label: "Action",
            noLoop: true,
            disablePadding: true,
        }
    ];
    const [stickerDishId, setStickerDishId] = React.useState(0);
    const [stickerCount, setStickerCount] = React.useState(0);
    const [manufacturingDate, setManufacturingDate] = React.useState(null);


    useEffect(() => {
        setLoading(true);
        try {
            axios.get("dishes/").then((response) => {
                if (response.status === 200) {
                    setDishes(response.data);
                    setFilteredDishes(response.data);
                } else {
                    console.log("Something went wrong");
                }

                setLoading(false);
            });

            axios.get("tags/").then((response) => {
                if (response.status === 200) {
                    setTags(response.data);
                }
            });
        } catch (err) {
            console.log(err);

            setLoading(false);
        }
    }, []);
    const EditButton = ({ row }) => (
        <IconButton
            onClick={() => {
                navigate("/create-dish", {
                    state: row,
                });
            }}
        >
            <EditTwoTone color="primary" />
        </IconButton>
    )
    const ImagePreview = ({ row }) => (
        <img src={process.env.REACT_APP_MEDIA_URL + row.photo} alt="preview" style={{ height: '100px' }} />
    );

    const handleTagChange = (event) => {
        var filteredDishes = [];
        if (event.target.value.includes('None')) {
            setSelectedTags(['None']);
            filteredDishes = dishes.filter((dish) => {
                return dish.tags.length === 0;
            });

        } else {
            setSelectedTags(event.target.value);
            filteredDishes = dishes.filter((dish) => {
                return dish.tags.some((tag) =>
                    event.target.value.includes(tag)
                );
            });
        }

        setFilteredDishes(filteredDishes);
        if (event.target.value.length === 0) {
            setFilteredDishes(dishes);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        const filteredDishes = dishes.filter((dish) => {
            return dish.name.toLowerCase().includes(event.target.value.toLowerCase());
        });
        setFilteredDishes(filteredDishes);
    };

    const handleDownloadDishes = () => {
        const dishesWithPhotoUrl = dishes.map(dish => ({
            ...dish,
            photo: process.env.REACT_APP_MEDIA_URL + dish.photo
        }));

        const ws = XLSX.utils.json_to_sheet(dishesWithPhotoUrl);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Dishes');
        XLSX.writeFile(wb, 'dishes.xlsx');
    };
    const handleDownloadSticker = () => {
        let formattedDate = new Date(manufacturingDate).toLocaleDateString('en-GB');
        const postData = {
            dish_id: stickerDishId,
            sticker_count: stickerCount,
            manufacturing_date: formattedDate
        };
        try {
            axios.post("sticker-download-custom/", postData, { responseType: 'blob' })
                .then((response) => {
                    if (response.status === 200) {
                        const blob = new Blob([response.data], { type: response.headers['content-type'] });
                        const contentDisposition = response.headers['content-disposition'];
                        let dishName = dishes.find(dish => dish.id === stickerDishId)?.name;
                        let filename = `${dishName}_${stickerCount}_${manufacturingDate}.pdf`;// Default filename

                        // Extract filename from HTTP response headers
                        if (contentDisposition) {
                            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            const matches = filenameRegex.exec(contentDisposition);
                            if (matches != null && matches[1]) {
                                filename = matches[1].replace(/['"]/g, '');
                            }
                        }

                        FileSaver.saveAs(blob, filename);
                    } else {
                        console.log("Something went wrong");
                    }
                });


        } catch (err) {
            console.log(err);
        }
    };
    const body = (

        <div>
            <Button variant="contained" component={Link} to="/meals-page"
                sx={{ mb: 2 }}> <ArrowBack /> Back to Meals</Button>
            <Button
                onClick={() => {
                    navigate("/create-dish");
                }}
                color="primary"
                variant="contained"
                sx={{ ml: 2, mb: 2, alignSelf: "start" }}
                disabled
            >
                Create dish
            </Button>
            <Button
                onClick={handleDownloadDishes}
                color="primary"
                variant="contained"
                sx={{ ml: 2, mb: 2, alignSelf: "start" }}
            >
                Download Dishes
            </Button>

            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="h6">Filter</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="search"
                                label="Search by dish name"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl sx={{ width: 300 }}>
                                <InputLabel id="tags-label" shrink={selectedTags.length > 0 || isSelectFocused}>Tags</InputLabel>
                                <Select
                                    input={
                                        <OutlinedInput
                                            label="Tags"
                                            id="tags-select"
                                            notched={selectedTags.length > 0 || isSelectFocused}
                                            onFocus={() => setIsSelectFocused(true)}
                                            onBlur={() => setIsSelectFocused(false)}
                                        />
                                    }
                                    labelId="tags-label"
                                    id="tags-select"
                                    multiple
                                    value={selectedTags}
                                    renderValue={(selected) => selected.join(', ')}
                                    onChange={handleTagChange}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    {tags.map((tag) => (
                                        <MenuItem key={tag.id} value={tag.name}>
                                            <Checkbox checked={selectedTags.includes(tag.name)} />
                                            {tag.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button
                                onClick={() => {
                                    setFilteredDishes(dishes);
                                    setSelectedTags([]);
                                    setSearchTerm('');
                                }}
                                color="primary"
                                variant="contained"
                                sx={{ ml: 2 }}
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="number"
                                label="Dish ID"
                                onChange={(event) => setStickerDishId(event.target.value ? parseInt(event.target.value) : 0)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="number"
                                label="Sticker Count"
                                onChange={(event) => setStickerCount(event.target.value ? parseInt(event.target.value) : 0)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="date"
                                label="Manufacturing Date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => setManufacturingDate(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDownloadSticker}
                                disabled={!stickerCount || !manufacturingDate || !dishes.some(dish => dish.id === stickerDishId)}
                            >
                                Download Sticker
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <CustomTable
                rows={filteredDishes}
                loading={loading}
                headCells={headCells}
                ActionButton={EditButton}
                ImagePreview={ImagePreview}

            ></CustomTable>
        </div>
    );

    return <Layout body={body} title={"Dishes"} />;
};

export default DishList;
