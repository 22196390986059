import React, { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import { Button, TextField, FormControlLabel, Switch, Grid, Box, FormControl, InputLabel, Input, Typography } from '@mui/material';
import Layout from '../../../layout/layout';

import { useLocation } from "react-router-dom";

import { PhotoCamera, ArrowBack } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

function CreateEvent() {
    const location = useLocation();
    const event = location.state;
    const navigate = useNavigate();
    const [isPhotoChanged, setIsPhotoChanged] = useState(false);
    const [eventData, setEventData] = useState({
        id: null,
        description: '',
        start_date: '',
        name: '',
        is_expired: false,
        end_date: '',
        photo: null,
        has_catalog: false,
        has_custom: false,
        box_price: 0.0,
    });
    const [durations, setDurations] = useState(""); // replace with your initial durations

    const [previewImage, setPreviewImage] = React.useState(null);
    const [isEditMode, setIsEditMode] = useState(false);

    const handleFileChange = (e) => {
        setEventData({ ...eventData, photo: e.target.files[0] });

        // Create a URL for the selected file to preview it
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
        setIsPhotoChanged(true);
    };

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setEventData({ ...eventData, [e.target.name]: value });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        if (isEditMode) {
            formData.append('id', eventData.id);
        }
        formData.append('name', eventData.name);
        formData.append('description', eventData.description);
        formData.append('start_date', formatDate(eventData.start_date));
        formData.append('end_date', formatDate(eventData.end_date));
        formData.set('is_expired', eventData.is_expired ? 1 : 0);
        const durationsArray = durations.split(',').map(Number);
        formData.set('durations', durationsArray);
        formData.set('has_catalog', eventData.has_catalog ? 1 : 0); formData.set('has_custom', eventData.has_custom ? 1 : 0);
        formData.append('box_price', eventData.box_price);
        if (isPhotoChanged) {
            formData.append('photo', eventData.photo);
        }
        else {
            formData.append('photo', null);
        }
        try {
            if (isEditMode) {
                const response = await axios.put('event/update/', formData);
                console.log(response.data);
                alert('Event updated successfully!');
            } else {
                const response = await axios.post('event/create/', formData);
                console.log(response.data);
                alert('Event created successfully!');
            }

        } catch (error) {
            console.error(error);
            console.error(error.response.data);
        }
    };

    const formatDate = (dateString) => {
        return dayjs(dateString).format('YYYY-MM-DD');
    };


    useEffect(() => {
        const event = location.state;
        if (event) {
            setIsEditMode(true);
            setEventData(event);
            setPreviewImage(process.env.REACT_APP_MEDIA_URL + event.photo);
            let formattedEvent = { ...event };
            formattedEvent.start_date = formatInputDate(event.start_date)
            formattedEvent.end_date = formatInputDate(event.end_date);
            setEventData(formattedEvent);
            if (event.durations) {
                const newDurations = event.durations.join(',');
                setDurations(newDurations);
            }

        }
    }, [location]);


    const formatInputDate = (dateStr) => {
        const [day, month, year] = dateStr.split("/");
        return `${year}-${month}-${day}`;
    };
    const body = (
        <Box sx={{ flexGrow: 1 }}>
            <Button variant="contained" component={Link} to="/events"
                sx={{ mb: 2 }}><ArrowBack /> Back to Event List</Button>

            <Button variant="contained" onClick={() => navigate('/event-dishes/', { state: { event: event } })} sx={{ mb: 2, ml: 2 }} disabled={!isEditMode}>Dishes</Button>

            <Button variant="contained" component={Link} to="/event-dish-categories/" sx={{ mb: 2, ml: 2 }} disabled={!isEditMode}>Dish categories</Button>

            <Typography variant="h4" gutterBottom>
                {isEditMode ? 'Edit ' : 'Create '}
                Event
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <TextField name="name" label="Event Name" value={eventData.name} onChange={handleChange} />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                name="description"
                                label="Description"
                                value={eventData.description}
                                onChange={handleChange}
                                multiline
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <InputLabel shrink htmlFor="start_date">
                                Start Date
                            </InputLabel>
                            <Input name="start_date" id="start_date" type="date" value={eventData.start_date} onChange={handleChange} />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <InputLabel shrink htmlFor="end_date">
                                End Date
                            </InputLabel>
                            <Input name="end_date" id="end_date" type="date" value={eventData.end_date} onChange={handleChange} />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                value={durations}
                                label="Durations"
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (/^[0-9,]*$/.test(newValue)) {
                                        setDurations(newValue);
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                name="box_price"
                                label="Box Price"
                                type="number"
                                step="0.01"
                                value={eventData.box_price}
                                onChange={handleChange}
                            />
                        </FormControl>


                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <FormControlLabel control={<Switch checked={eventData.is_expired} onChange={handleChange} name="is_expired" />} label="Is Expired?" />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <FormControlLabel control={<Switch checked={eventData.has_catalog} onChange={handleChange} name="has_catalog" />} label="Has Catalog?" />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <FormControlLabel control={<Switch checked={eventData.has_custom} onChange={handleChange} name="has_custom" />} label="Has Custom?" />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={<PhotoCamera />}
                            >
                                Upload Photo
                                <input
                                    type="file"
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </Button>
                            {previewImage && (
                                <Box sx={{ mt: 2 }}>
                                    <img src={previewImage} alt="Preview" style={{ width: '100%', height: 'auto' }} />
                                </Box>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
                <Button type="submit"
                    variant='contained'
                    disabled={!eventData.name || !eventData.description || !eventData.start_date}>
                    {isEditMode ? 'Edit ' : 'Create '} Event
                </Button>
            </form>
        </Box>
    );

    return (<Layout body={body} title={"Create Event"} />)
}

export default CreateEvent;