import { Box, Paper, Stack, TextField, Typography } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import LoadingButton from "@mui/lab/LoadingButton";
import logo from "../static/images/scale logo.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "../api/axios";
function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email !== "" && password !== "") {
      try {
        setLoading(true);
        const response = await axios.post(
          "/login/",
          JSON.stringify({ email, password }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response);
        setLoading(false);

        // TODO: switch to saving token and then using that token to authenticate in each request
        localStorage.setItem("isAuthenticated", "true");
        navigate("dashboard");
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  //write a regex to check if the email is valid
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  return (
    <Box bgcolor="background.default">
      <Paper
        sx={{
          mx: "auto",
          mt: 10,
          borderRadius: 2,
          p: 2,
          width: 300,
        }}
        elevation={2}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <img alt="Scale Kuwait" src={logo} height="60" />
          <Typography variant="h4">Scale Admin</Typography>
        </Stack>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            id="outlined-basic"
            label="Email"
            variant="outlined"
            required
            sx={{ width: "100%", mt: 2 }}
            error={!(validateEmail(email) || email === "")}
          />
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            id="outlined-password-input"
            label="Password"
            type="password"
            variant="outlined"
            required
            sx={{ width: "100%", mt: 2 }}
          />
          <LoadingButton
            // onClick={onClick}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            color="primary"
            type="submit"
            endIcon={<LoginIcon />}
            sx={{ width: "100%", mt: 2, mr: 4 }}
          >
            Send
          </LoadingButton>
        </form>
      </Paper>
    </Box>
  );
}

export default LoginPage;
