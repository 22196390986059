import React, { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Grid, Box, Typography } from '@mui/material';
import Layout from '../../../layout/layout';

import { useLocation } from "react-router-dom";

import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';

function CreateEventDishCategory() {
    const location = useLocation();
    const [eventData, setEventData] = useState({
        id:'',
        name: '',
        event_id: '',
        is_carb_changable: false,
        price: '',
        minimum_order: '',
        maximum_order: '',
    });
    const [events, setEvents] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get('event/list/');
                setEvents(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchEvents();
    }, []);

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setEventData({ ...eventData, [e.target.name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (isEditMode) {
                console.log(eventData);
                const response = await axios.put('event/dish_category/update/', eventData);

                console.log(response.data);
                alert('Event Dish Category updated successfully!');
            } else {
                const response = await axios.post('event/dish_category/create/', eventData);
                console.log(response.data);
                alert('Event Dish Category created successfully!');
            }

        } catch (error) {
            console.error(error);
            console.error(error.response.data);
        }
    };

    useEffect(() => {
        const fetchEvents = async () => {
            const response = await axios.get('events/');
            setEvents(response.data);
        };

        fetchEvents();

        const eventDishCategory = location.state;
        
        if (eventDishCategory) {
            console.log(eventDishCategory);
            setIsEditMode(true);
            setEventData(eventDishCategory);
        }
    }, [location]);

    const body = (
        <Box sx={{ flexGrow: 1 }}>
            <Button variant="contained" component={Link} to="/event-dish-categories"
                sx={{ mb: 2 }}><ArrowBack /> Back to Event Dish Category List</Button>

            <Typography variant="h4" gutterBottom>
                {isEditMode ? 'Edit ' : 'Create '}
                Event Dish Category
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <TextField name="name" label="Name" value={eventData.name} onChange={handleChange} />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <InputLabel id="event-label">Event</InputLabel>
                            <Select
                                labelId="event-label"
                                id="event_id"
                                name="event_id"
                                value={eventData.event_id}
                                onChange={handleChange}
                            >
                                {events.map((event) => (
                                    <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <FormControlLabel control={<Switch checked={eventData.is_carb_changable} onChange={handleChange} name="is_carb_changable" />} label="Is Carb Changable?" />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <TextField 
                                name="price" 
                                label="Price" 
                                value={eventData.price} 
                                onChange={handleChange} 
                                type="number" 
                                inputProps={{ step: 0.01 }}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <TextField 
                                name="minimum_order" 
                                label="Minimum Order" 
                                value={eventData.minimum_order} 
                                onChange={handleChange} 
                                type="number" 
                                inputProps={{ min: 0 }}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <TextField 
                                name="maximum_order" 
                                label="Maximum Order" 
                                value={eventData.maximum_order} 
                                onChange={handleChange} 
                                type="number" 
                                inputProps={{ min: 0 }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Button type="submit"
                    variant='contained'
                    disabled={!eventData.name || !eventData.event_id || !eventData.price || !eventData.minimum_order}>{isEditMode ? 'Edit ' : 'Create '} Event Dish Category</Button>
            </form>
        </Box>
    );

    return (<Layout body={body} title={"Create Event Dish Category"} />)
}

export default CreateEventDishCategory;