import React, { useState } from 'react';
import Layout from "../../../layout/layout";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField, Button, Box, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import axios from "../../../api/axios";
import { format } from 'date-fns';




const MenuPage = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate2, setSelectedDate2] = useState(new Date());
    const [selectedDate3, setSelectedDate3] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };

    const handleDateChange3 = (date) => {
        setSelectedDate3(date);
    };

    const disableNonSaturdays = (date) => {
        return date.getDay() !== 6;
    };

    const handleSubmit = () => {
        const service_date = format(selectedDate, "yyyy-MM-dd");
        const new_date = format(selectedDate2, "yyyy-MM-dd");

        const data = {
            service_date,
            new_date
        };
        try {
            axios.post("menu/copy-service-date/", data)
                .then(response => {
                    if (response.status === 200) {
                        alert('Success!');
                    } else {
                        alert('Error!');
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert('Error!');
                });
        }
        catch (error) {
            console.log(error);
            alert('Error!');
        }

    };

    const body = (
        <div>
            <Typography variant="h6" marginBottom={2}>Copy menu</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>

                <Box display="flex" gap={2} alignItems="center">
                    <DatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        label="Original Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(props) => <TextField {...props} fullWidth />}
                    />
                    <ArrowRightAltIcon />
                    <DatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        label="Copy to"
                        value={selectedDate2}
                        onChange={handleDateChange2}
                        renderInput={(props) => <TextField {...props} fullWidth />}
                    />
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </LocalizationProvider>
        </div>
    );

    return <Layout body={body} title={"Menu"} />;
};

export default MenuPage;