import * as React from "react";
import { useState, useEffect } from "react";
import axios from "../../../api/axios";
import CustomTable from "../../../components/customTable";
function TagList() { 
    const [dishes, setDishes] = useState([]);
    const [loading, setLoading] = useState(false);
    const headCells = [
        {
            id: "id",
            numeric: false,
            disablePadding: true,
            label: "ID",
        },
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Tag",
        },
        {
            id: "dish_count",
            numeric: false,
            disablePadding: false,
            label: "Dish Count",
        }
    ];

    useEffect(() => {
        setLoading(true);
        try {
            axios.get("tags/").then((response) => {
                console.log(response);
                if (response.status === 200) {
                    console.log(response.data);
                    setDishes(response.data);
                    setLoading(false);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <div>
            <CustomTable
                headCells={headCells}
                rows={dishes}
                loading={loading}
                noLoop={true}
            />
        </div>
    );
}
export default TagList;