import React, { useState, useEffect } from 'react';
import Layout from "../../../layout/layout";
import { Table, TableBody, TableCell, TableContainer, TableRow, Checkbox, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Box, TextField, Button, List, ListItem, ListItemText } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from "../../../api/axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { format } from 'date-fns';

function CreateMenuPage() {
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());

    const [breakfastList, setBreakfastList] = useState([]);
    const [selectedBreakfast, setSelectedBreakfast] = useState([]);
    const [mealList, setMealList] = useState([]);
    const [selectedMeal, setSelectedMeal] = useState([]);
    const [snacksList, setSnacksList] = useState([]);
    const [selectedSnacks, setSelectedSnacks] = useState([]);
    const [sweetList, setSweetList] = useState([]);
    const [selectedSweet, setSelectedSweet] = useState([]);

    useEffect(() => {
        setLoading(true);
        try {
            axios.get("dishes/").then((response) => {
                if (response.status === 200) {
                    let breakfastList = response.data.filter(item => item.category === 'BR');
                    let mealsList = response.data.filter(item => item.category === 'ML');
                    let sweetsList = response.data.filter(item => item.category === 'SW');
                    let snacksList = response.data.filter(item => item.category === 'SN');

                    setBreakfastList(breakfastList);
                    setMealList(mealsList);
                    setSweetList(sweetsList);
                    setSnacksList(snacksList);
                    
                } else {
                    console.log("Something went wrong");
                }

                setLoading(false);
            });


        } catch (err) {
            console.log(err);

            setLoading(false);
        }
    }, []);

    const SelectableTable = ({ items, selectedItems, setSelectedItems }) => {
        const handleSelect = (item) => {
            if (selectedItems.includes(item)) {
                setSelectedItems(selectedItems.filter(i => i !== item));
            } else {
                setSelectedItems([...selectedItems, item]);
            }
        };
        return (
            <TableContainer style={{ maxHeight: 500, overflow: 'auto' }} >
                <Table>
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow key={item.id}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedItems.includes(item)}
                                        onChange={() => handleSelect(item)}
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <img src={process.env.REACT_APP_MEDIA_URL + item.photo} alt="preview" style={{ height: '50px' }} />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }


    const DraggableList = ({ items, setItems }) => {
        const handleOnDragEnd = (result) => {
            if (!result.destination) return;
            const itemsCopy = Array.from(items);
            const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
            itemsCopy.splice(result.destination.index, 0, reorderedItem);

            setItems(itemsCopy);
        }
        return (
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="items">
                    {(provided) => (
                        

                        <List {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                    {(provided) => (
                                        <ListItem {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <ListItemText primary={`${index + 1}. ${item.name}`} />
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    const handleDateChange = (newValue) => {
        setDate(newValue);
    }

    const handleSubmit = () => {
        const service_date = format(date, "yyyy-MM-dd");
        let combined = [...selectedBreakfast, ...selectedMeal, ...selectedSnacks, ...selectedSweet];

        let menu = combined.map((item, index) => {
            return {
                'dish_id': item.id,
                'order': index + 1
            }
        });
        console.log(menu);
        const data = {
            date: service_date,
            menu: menu
        }; 
        try {
            axios.post("menu/create/", data)
                .then(response => {
                    if (response.status === 201) {
                        alert('Success!');
                    } else {
                        alert('Error!');
                    }
                }
            )
                .catch(error => {
                    console.log(error);
                    alert('Error!');
                });
        }
        catch (error) {
            console.log(error);
            alert('Error!');
        }
    }
    const body = (
        <div>
            <h1>Create Menu</h1>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <Box display="flex" gap={2} alignItems="center">
                            <DatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                label="Date"
                                value={date}
                                onChange={handleDateChange}
                                renderInput={(props) => <TextField {...props} fullWidth />}
                            />
                        </Box>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" color="primary" sx={{ mr: 2, mb: 2 }}
                        onClick={() => {
                           handleSubmit();
                        }}>
                        Submit Menu
                    </Button>
                </Grid>

            </Grid>
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Breakfasts</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SelectableTable
                                items={breakfastList}
                                selectedItems={selectedBreakfast}
                                setSelectedItems={setSelectedBreakfast}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Meals</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SelectableTable
                                items={mealList}
                                selectedItems={selectedMeal}
                                setSelectedItems={setSelectedMeal}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography>Snacks</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SelectableTable
                                items={snacksList}
                                selectedItems={selectedSnacks}
                                setSelectedItems={setSelectedSnacks}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography>Sweets</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SelectableTable
                                items={sweetList}
                                selectedItems={selectedSweet}
                                setSelectedItems={setSelectedSweet}
                            />
                        </AccordionDetails>
                    </Accordion>

                </Grid>
                <Grid item xs={6}>
                    <h3>Breakfasts</h3>
                    <DraggableList items={selectedBreakfast} setItems={setSelectedBreakfast} />
                    <h3>Meals</h3>
                    <DraggableList items={selectedMeal} setItems={setSelectedMeal} />
                    <h3>Snacks</h3>
                    <DraggableList items={selectedSnacks} setItems={setSelectedSnacks} />
                    <h3>Sweets</h3>
                    <DraggableList items={selectedSweet} setItems={setSelectedSweet} />
                </Grid>
            </Grid>
        </div>
    );
    return <Layout body={body} title={"Menu"} />;
}
export default CreateMenuPage;