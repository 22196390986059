import Layout from "../../layout/layout";
import DriverListDownload from "./parts/driverListDownload";
import OderListPerMonth from "./parts/oderListPerMonth";
import OrderListCustomDate from "./parts/orderListCustomDate";
import OrderListByDish from "./parts/orderListByDish"; // Import the component
import ChefList1100Calories from "./parts/chefList1100CaloriesDownload";
import ChefList1250Calories from "./parts/chefList1250CaloriesDownload";

function OrderListPage() {
    

    const body = (
        <>
            <div>
                <DriverListDownload />
            </div>
            <div>
                <OderListPerMonth />
            </div>
            <div>
                <OrderListCustomDate /> 
            </div>
            <div>
                <OrderListByDish /> {/* Use the imported component */}
            </div>
            <div>
                <ChefList1100Calories /> {/* Use the imported component */}
            </div>
            <div>
                <ChefList1250Calories /> {/* Use the imported component */}
            </div>

        </>
    );
    return <Layout body={body} title={"Order Lists Download"} />;

}

export default OrderListPage;
