import TagList from "./tagList";
import Layout from "../../../layout/layout";
import { TextField, Grid, Button, Snackbar, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from '../../../api/axios';

import { useState } from 'react';

function TagsPage() {
    const [tagName, setTagName] = useState('');

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    const handleCreateTag = async () => {
        try {
            const response = await axios.post('/tags/create/', { tag_name: tagName });
            if (!response.ok) {
                setAlertMessage(response.data.error);
                setAlertSeverity('error');
                setAlertOpen(true);
            } else {
                window.location.reload(false);
            }
            // handle success
        } catch (error) {
            setAlertMessage(error);
            setAlertSeverity('error');
            setAlertOpen(true);
            // handle error
        }

    };

    const body = (
        <>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12} pt={2}>
                    <Button variant="contained" component={Link} to="/meals-page">🔙Back to Meals</Button>
                </Grid>
                <Grid item xs={12} md={8}>
                    <div className="container">
                        <TagList />
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid item xs={12} md={6}>
                        <TextField label="Enter tag name" variant="outlined" onChange={(event) => setTagName(event.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={6} pt={2}>
                        <Button variant="contained" onClick={handleCreateTag}>Create Tag</Button>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
    return <Layout body={body} title={"Tags"} />;

}

export default TagsPage;
