import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import CustomTable from "../../../components/customTable";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EditTwoTone } from "@mui/icons-material";

export default function EventDishList(props) { 
    const navigate = useNavigate();
    const [eventDishList, setEventDishList] = useState([]);
    const [loading, setLoading] = useState(true);

    const headCells = [
        {
            id: "photo",
            numeric: false,
            disablePadding: true,
            label: "Photo",
            noLoop: true,
        },
        {
            id: "id",
            numeric: false,
            disablePadding: true,
            label: "ID",
        },
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
        },
        {
            id: "category",
            numeric: false,
            disablePadding: false,
            label: "Category",
        },
        
        {
            id: "action",
            numeric: false,
            label: "Action",
            noLoop: true,
            disablePadding: true,
        }
    ];

    useEffect(() => {
        const event_id = props.event.id;
        axios
            .post("event/dish/list/", { event_id: event_id })
            .then((response) => {
                setEventDishList(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                console.error(error.response.data);
                setLoading(false);
            });
        
    }, []);

    const ImagePreview = ({ row }) => (
        <img src={process.env.REACT_APP_MEDIA_URL + row.photo} alt="preview" style={{ height: '100px' }} />
    );
    const EditButton = ({ row }) => (
        <IconButton
            onClick={() => {
                navigate("/create-event-dish", {
                    state: {dish:row, event: props.event }
                });
            }}
        >
            <EditTwoTone color="primary" />
        </IconButton>
    )


    return (
        <div>
            <CustomTable
                rows={eventDishList}
                loading={loading}
                headCells={headCells}
                ActionButton={EditButton}
                ImagePreview={ImagePreview}

            ></CustomTable>
        </div>
    );
}