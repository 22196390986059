
import * as React from 'react';
import { useLocation } from "react-router-dom";
import Layout from '../../../layout/layout';
import { TextField, Select, MenuItem, InputLabel, FormControl, Button, Box, Typography, Grid } from '@mui/material';
import { PhotoCamera, ArrowBack } from '@mui/icons-material';
import axios from "../../../api/axios";
import { Link } from 'react-router-dom';
import { set } from 'date-fns';

export default function CreateDish() {
    const location = useLocation();
    const [dishId, setDishId] = React.useState(null);
    const [dishName, setDishName] = React.useState('');
    const [dishCategory, setDishCategory] = React.useState('');
    const [details, setDetails] = React.useState('');
    const [tags, setTags] = React.useState([]);
    const [proteins, setProteins] = React.useState('');
    const [fats, setFats] = React.useState('');
    const [calories, setCalories] = React.useState('');
    const [carbs, setCarbs] = React.useState('');
    const [photo, setPhoto] = React.useState(null);
    const [tagList, setTagList] = React.useState([]);
    const [isEditMode, setIsEditMode] = React.useState(false);

    const handleDishNameChange = (event) => {
        setDishName(event.target.value);
    };

    const handleDishCategoryChange = (event) => {
        setDishCategory(event.target.value);
    };

    const handleDetailsChange = (event) => {
        setDetails(event.target.value);
    };

    const handleTagsChange = (event) => {
        setTags(event.target.value);
    };

    const handleProteinsChange = (event) => {
        setProteins(event.target.value);
    };

    const handleFatsChange = (event) => {
        setFats(event.target.value);
    };

    const handleCaloriesChange = (event) => {
        setCalories(event.target.value);
    };

    const handleCarbsChange = (event) => {
        setCarbs(event.target.value);
    };

    const handlePhotoChange = (event) => {
        setPhoto(URL.createObjectURL(event.target.files[0]));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const dishData = {
            dish_id: dishId,
            name: dishName,
            category: dishCategory,
            details: details,
            tags: tags,
            proteins: proteins,
            fats: fats,
            calories: calories,
            carbs: carbs
        };
        if (isEditMode) {
            axios.post("dishes/edit/", dishData).then((response) => {
                if (response.status === 200) {
                    alert("Dish updated successfully");
                }
                else {
                    alert("Something went wrong");
                }
            });
        } else {
            axios.post("dishes/", dishData).then((response) => {
                if (response.status === 201) {
                    alert("Dish created successfully");
                }
            });
        }
    };
    const isFormValid = () => {
        return dishName && dishCategory && photo;
    }

    React.useEffect(() => {
        const dish = location.state;
        if (dish) {
            setIsEditMode(true);
            setDishId(dish.id);
            setDishName(dish.name);
            setDishCategory(dish.category);
            setDetails(dish.details);
            setTags(dish.tags);
            setProteins(dish.proteins);
            setFats(dish.fats);
            setCalories(dish.calories);
            setCarbs(dish.carbs);
            setPhoto(process.env.REACT_APP_MEDIA_URL + dish.photo);
        }
    }, [location]);


    React.useEffect(() => {
        try {
            axios.get("tags/").then((response) => {
                if (response.status === 200) {
                    setTagList(response.data);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }, []);


    const body = (
        <Box sx={{ flexGrow: 1 }}>
            <Button variant="contained" component={Link} to="/dishes"
                sx={{ mb: 2 }}><ArrowBack /> Back to Dish List</Button>

            <Typography variant="h4" gutterBottom>
                {isEditMode ? 'Edit ' : 'Create '}
                Dish
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="dish-category-label">Dish Category</InputLabel>
                            <Select
                                labelId="dish-category-label"
                                id="dish-category"
                                value={dishCategory}
                                onChange={handleDishCategoryChange}
                            >
                                <MenuItem value="BR">Breakfast</MenuItem>
                                <MenuItem value="ML">Meal</MenuItem>
                                <MenuItem value="SN">Snack</MenuItem>
                                <MenuItem value="SW">Sweet</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="dish-name"
                                label="Dish Name"
                                value={dishName}
                                onChange={handleDishNameChange}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="details"
                                label="Details"
                                multiline
                                rows={2}
                                value={details}
                                onChange={handleDetailsChange}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="tags-label">Tags</InputLabel>
                            <Select
                                labelId="tags-label"
                                id="tags"
                                multiple
                                value={tags}
                                onChange={handleTagsChange}

                            >
                                {tagList.map((tag) => (
                                    <MenuItem key={tag.id} value={tag.name}>
                                        {tag.name}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="proteins"
                                label="Proteins (g)"
                                value={proteins}
                                onChange={handleProteinsChange}
                                type="number"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="carbs"
                                label="Carbs (g)"
                                value={carbs}
                                onChange={handleCarbsChange}
                                type="number"
                            />
                        </FormControl>


                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ height: '240px', position: 'relative' }}>
                            {photo && (
                                <img
                                    src={photo}
                                    alt="Dish"
                                    style={{
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            )}
                        </Box>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={<PhotoCamera />}
                                disabled
                            >
                                Upload Photo
                                <input
                                    type="file"
                                    hidden
                                    onChange={handlePhotoChange}
                                />
                            </Button>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="fats"
                                label="Fats (g)"
                                value={fats}
                                onChange={handleFatsChange}
                                type="number"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="calories"
                                label="Calories"
                                value={calories}
                                onChange={handleCaloriesChange}
                                type="number"
                            />
                        </FormControl>

                    </Grid>
                </Grid>
                <Box sx={{ mt: 2 }}>
                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={!isFormValid()}
                        onClick={handleSubmit}
                    >
                        {isEditMode ? 'Update' : 'Create'}
                    </Button>
                </Box>
            </form>
        </Box>
    );

    return (
        <Layout body={body} location={location} />
    );
}