import * as React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Layout from "../../layout/layout";
import axios from "../../api/axios";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { format } from "date-fns";
import EnhancedTable from "./userTable";

import Search from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


export default function UsersPage() {
  const [users, setUsers] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [subscriptionStartDate, setSubscriptionStartDate] = React.useState(
    new Date()
  );
  const [subscriptionEndDate, setSubscriptionEndDate] = React.useState(
    new Date()
  );
  const [loading, setLoading] = React.useState(true);
  const [startAge, setStartAge] = React.useState(0);
  const [endAge, setEndAge] = React.useState(100);
  const [backendListTitle, setBackendListTitle] = React.useState("All users");
  const [frontendListTitle, setFrontendListTitle] = React.useState("");
  const [gender, setGender] = React.useState("Any")
  const [areaList, setAreaList] = React.useState([])
  const [selectedArea, setSelectedArea] = React.useState('All')
  const [driver, setDriver] = React.useState('All')
  const [searchString, setSearchString] = React.useState('')
  const [birthMonth, setBirthMonth] = React.useState('All')
  const [packageFilter, setPackageFilter] = React.useState('All')
  const [totalSub, setTotalSub] = React.useState(0)

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Current active user " + fileName + fileExtension);
  };


  const resetTitle = () => {
    setBackendListTitle("");
    setFrontendListTitle("");
  }

  const frontendFilter = () => {
    setLoading(true);
    var tempTitle = "and ";
    var tempList = users;
    // gender filter
    tempTitle = tempTitle + "having " + gender + " gender";
    if (gender !== 'Any') {
      tempList = tempList.filter(user => {
        if (gender === 'Male') {
          return user.gender === 'M'
        }
        if (gender === 'Female') {
          return user.gender === 'F'
        }
      });
    }

    // age filter
    tempTitle = tempTitle + `, between age ${startAge} - ${endAge}`
    tempList = tempList.filter(user => {
      return user.age >= startAge && user.age <= endAge;
    });

    // area filter
    if (selectedArea !== 'All') {
      tempTitle = tempTitle + `, in ${selectedArea}`
      tempList = tempList.filter(user => {
        return user.area === selectedArea;
      });
    }

    // driver filter
    if (driver !== 'All') {
      tempTitle = tempTitle + `, driver ${driver}`
      tempList = tempList.filter(user => {
        return user.driver === parseInt(driver);
      });
    }

    // birth month filter
    if (birthMonth !== 'All') {
      tempTitle = tempTitle + `, birth month ${birthMonth}`
      tempList = tempList.filter(user => {
        console.log(user.birthday_month)
        return parseInt(user.birthday_month) === parseInt(birthMonth);
      });
    }

    // package filter
    if (packageFilter !== 'All') {
      tempTitle = tempTitle + `, package ${packageFilter}`;
      tempList = tempList.filter(user => user.package === packageFilter);
    }


    // set data
    setFrontendListTitle(tempTitle);
    setFilteredList(tempList);
    setRows(tempList);
    setLoading(false);
  }

  //serach list
  const searchList = (s) => {
    setLoading(true);
    var tempTitle = "and ";
    var tempList = filteredList;
    // search string filter
    tempTitle = tempTitle + "having " + s;
    if (searchString !== '') {
      tempList = tempList.filter(user => {
        try {
          return user.name.toLowerCase().includes(s.toLowerCase()) || user.email.toLowerCase().includes(s.toLowerCase()) || user.phone.toLowerCase().includes(s.toLowerCase()) || user.id.toString().includes(s.toLowerCase());
        }
        catch (e) {
          return user.name.toLowerCase().includes(s.toLowerCase()) || user.email.toLowerCase().includes(s.toLowerCase()) || user.id.toString().includes(s.toLowerCase());
        }
      });
    }
    setRows(tempList);
    setLoading(false);
  }


  const getUsersWithFilter = (url, body) => {
    setLoading(true);
    const fetchData = async () => {
      const response = await axios.post(url, body);
      setUsers(response.data);
      setRows(response.data);
      setFilteredList(response.data);
      setLoading(false);
    };
    fetchData();
  };
  const getUsers = (url) => {
    setLoading(true);
    const fetchData = async () => {
      const response = await axios.get(url);
      setUsers(response.data);
      setRows(response.data);
      setFilteredList(response.data);
      setLoading(false);
    };
    fetchData();
  };

  const getAreas = async () => {
    const response = await axios.get("/get_area_list/");
    setAreaList(response.data);
  }
  React.useEffect(() => {
    getUsers("user/list/");
    getAreas();
  }, []);


  const body = (
    <div>
      <Grid item>
        <Button variant="contained" sx={{ height: "100%" }}
          onClick={() => {
            getUsers("user/list/");
            resetTitle();
            setBackendListTitle("All users");
          }}
        >
          Get all users
        </Button>
      </Grid>
      <Paper sx={{ mt: 2, mb: 2 }}>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item sx={{ width: "100%" }}>
            <Typography variant="h6">
              Get user based on subscription:
            </Typography>
          </Grid>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item sx={{ my: 'auto' }}>
              <Button variant="contained" sx={{ height: "100%" }}
                onClick={() => {
                  getUsers("user/get_current_active_user/");
                  resetTitle();
                  setBackendListTitle("Current active user");
                }}
              >
                Get current active users
              </Button>
            </Grid>
            <Grid item sx={{ my: 'auto' }}>
              <Button variant="contained" sx={{ height: "100%" }}
                onClick={() => {
                  getUsers("user/get_first_time_subscription_user/");
                  resetTitle();
                  setBackendListTitle("First time active users");
                }}
              >
                Get first time active users
              </Button>
            </Grid>

          </Grid>

          <Grid item>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="dd/MM/yyyy"
                value={subscriptionStartDate}
                onChange={(newValue) => {
                  setSubscriptionStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                label="End Date"
                inputFormat="dd/MM/yyyy"
                value={subscriptionEndDate}
                onChange={(newValue) => {
                  setSubscriptionEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sx={{ my: "auto" }}>
            <Button
              variant="contained"
              sx={{ height: "70%" }}
              onClick={() => {
                // getUsers('user/subscription/');
                getUsersWithFilter("user/subscription/", {
                  start_day: subscriptionStartDate.getDate(),
                  start_month: subscriptionStartDate.getMonth() + 1,
                  start_year: subscriptionStartDate.getFullYear(),
                  end_day: subscriptionEndDate.getDate(),
                  end_month: subscriptionEndDate.getMonth() + 1,
                  end_year: subscriptionEndDate.getFullYear(),
                  fast_calculation: true,
                });

                resetTitle();
                setBackendListTitle(
                  "Active users between " +
                  format(subscriptionStartDate, "dd/MM/yyyy") +
                  " and " +
                  format(subscriptionEndDate, "dd/MM/yyyy")
                );
              }}
            >
              Get active User (fast)
            </Button>
          </Grid>
          <Grid item sx={{ my: "auto" }}>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ height: "70%" }}
              onClick={() => {
                // getUsers('user/subscription/');
                getUsersWithFilter("user/subscription/", {
                  start_day: subscriptionStartDate.getDate(),
                  start_month: subscriptionStartDate.getMonth() + 1,
                  start_year: subscriptionStartDate.getFullYear(),
                  end_day: subscriptionEndDate.getDate(),
                  end_month: subscriptionEndDate.getMonth() + 1,
                  end_year: subscriptionEndDate.getFullYear(),
                  fast_calculation: false,
                });
                resetTitle();
                setBackendListTitle(
                  "Active users between " +
                  format(subscriptionStartDate, "dd/MM/yyyy") +
                  " and " +
                  format(subscriptionEndDate, "dd/MM/yyyy") +
                  " (slow calculation, will take a while)"
                );
              }}
            >
              Get active User (precise & slow)
            </Button>
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item>
            <TextField
              type="number"
              label="Total Subscriptions"
              value={totalSub}
              onChange={(event) => {
                const value = event.target.value ? parseInt(event.target.value) : 0;
                if (value >= 0) {
                  setTotalSub(value);
                }
              }}
            />
          </Grid>
          <Grid item sx={{ my: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ height: "70%" }}
              onClick={() => {
                // getUsers('user/subscription/');
                getUsersWithFilter("user/subscription/start/", {
                  start_day: subscriptionStartDate.getDate(),
                  start_month: subscriptionStartDate.getMonth() + 1,
                  start_year: subscriptionStartDate.getFullYear(),
                  end_day: subscriptionEndDate.getDate(),
                  end_month: subscriptionEndDate.getMonth() + 1,
                  end_year: subscriptionEndDate.getFullYear(),
                  total_sub:totalSub,
                });
                resetTitle();
                var temp = "Users started subscription between " +
                  format(subscriptionStartDate, "dd/MM/yyyy") +
                  " and " +
                  format(subscriptionEndDate, "dd/MM/yyyy")
                if (totalSub !== 0) { 
                  temp = temp + " and having " + totalSub + " subscriptions"
                }
                console.log(totalSub)
                setBackendListTitle(temp);
              }}
            >
              Get subscription started users
            </Button>
          </Grid>


        </Grid>




      </Paper>
      <Paper sx={{ mt: 2, mb: 2, p: 2 }}>
        <Typography variant="h6" sx={{ mx: 'auto' }}>User info filter</Typography>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item >
            <Typography variant="p">
              Age:&nbsp;
            </Typography>
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '') {
                  setStartAge(0)
                }
                if (re.test(e.target.value)) {
                  setStartAge(parseInt(e.target.value))
                }

              }}
              size="small"
              value={startAge}
              sx={{ width: "60px" }}
            />
            &nbsp;to&nbsp;
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '') {
                  setEndAge(0)
                }
                if (re.test(e.target.value)) {
                  setEndAge(parseInt(e.target.value))
                }
              }}
              size="small"
              value={endAge}
              sx={{ width: "60px" }}
            />

          </Grid>
          <Grid item >
            <Typography variant="p">
              Gender
            </Typography>

            <FormControl sx={{ m: 1, width: 100 }} size='small'>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                label="Gender"
                onChange={(e) => { setGender(e.target.value) }}
              >
                <MenuItem value={'Any'}>Any</MenuItem>
                <MenuItem value={'Male'}>Male</MenuItem>
                <MenuItem value={'Female'}>Female</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item >
            <Typography variant="p">
              Area
            </Typography>
            <FormControl sx={{ m: 1, width: 100 }} size='small'>
              <InputLabel id="demo-simple-select-label">Area</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedArea}
                label="Gender"
                onChange={(e) => { setSelectedArea(e.target.value) }}
              >
                <MenuItem value={'All'}>All</MenuItem>
                {areaList.map((value, index) => {
                  return <MenuItem value={value}>{value}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item >
            <Typography variant="p">
              Driver
            </Typography>
            <FormControl sx={{ m: 1, width: 100 }} size='small'>
              <InputLabel id="demo-simple-select-label">Driver</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={driver}
                label="Driver"
                onChange={(e) => { setDriver(e.target.value) }}
              >
                <MenuItem value={'All'}>All</MenuItem>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item >
            <Typography variant="p">
              Birth Month
            </Typography>
            <FormControl sx={{ m: 1, width: 100 }} size='small'>
              <InputLabel id="demo-simple-select-label">Month</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={birthMonth}
                label="Month"
                onChange={(e) => { setBirthMonth(e.target.value) }}
              >
                <MenuItem value={'All'}>All</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item >
            <Typography variant="p">
              Package
            </Typography>
            <FormControl sx={{ m: 1, width: 100 }} size='small'>
              <InputLabel id="demo-simple-select-label">Package</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={packageFilter}
                label="Package"
                onChange={(e) => { setPackageFilter(e.target.value) }}
              >
                <MenuItem value={'All'}>All</MenuItem>
                <MenuItem value={'Lifestyle'}>Lifestyle</MenuItem>
                <MenuItem value={'Bodybuilding'}>Bodybuilding</MenuItem>
                <MenuItem value={'Scale'}>Scale</MenuItem>
                <MenuItem value={'Lunchbox'}>Lunchbox</MenuItem>
                <MenuItem value={'1100Calories'}>1100Calories</MenuItem>
                <MenuItem value={'1250Calories'}>1250Calories</MenuItem>
                <MenuItem value={'Custom'}>Custom</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Button variant="contained"

          onClick={frontendFilter}
        >
          Filter
        </Button>
      </Paper>
      <Search sx={{ color: 'action.active', mr: 1, my: 3.0 }} />
      <TextField
        label="Search (id, name, email, phone number)"
        variant="standard" sx={{ width: 450 }}

        value={searchString}
        onChange={(e) => {
          setSearchString(e.target.value)
          searchList(e.target.value);
        }
        }
      />
      <CancelIcon
        sx={{ color: 'action.active', mr: 1, my: 3.0 }}
        onClick={(e) => {
          setSearchString('')
          setRows(filteredList)
        }} />
      <br />
      <Button variant="contained" onClick={(e) => exportToCSV(rows, frontendListTitle)}>Download Excel</Button>
      <br />

      <EnhancedTable
        tableTitle={backendListTitle + " " + frontendListTitle}
        rows={rows}
        loading={loading}
        rowCount={rows.length}
      />
    </div>
  );
  return <Layout body={body} title={"Users table"} />;
}
