import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import CustomTable from "../../../components/customTable";
import { IconButton, Button, Select, MenuItem, InputLabel, FormControl, Grid, Typography, Checkbox, TextField, OutlinedInput } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EditTwoTone, ArrowBack } from "@mui/icons-material";

function EventList() {
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get("event/list/")
            .then((response) => {
                setEvents(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                console.error(error.response.data);
                setLoading(false);
            });
    }, []);

    const headCells = [

        {
            id: "photo",
            numeric: false,
            disablePadding: true,
            label: "Photo",
            noLoop: true,
        },
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name"
        },
        {
            id: "description",
            numeric: false,
            disablePadding: true,
            label: "Description"
        },
        {
            id: "start_date",
            numeric: false,
            disablePadding: true,
            label: "Start Date"
        },
        {
            id: "end_date",
            numeric: false,
            disablePadding: true,
            label: "End Date"
        },
        {
            id: "action",
            numeric: false,
            label: "Action",
            noLoop: true,
            disablePadding: true,
        }
    ];

    const ImagePreview = ({ row }) => (
        <img src={process.env.REACT_APP_MEDIA_URL + row.photo} alt="preview" style={{ height: '100px' }} />
    );
    const EditButton = ({ row }) => (
        <IconButton
            onClick={() => {
                navigate("/create-event", {
                    state: row,
                });
            }}
        >
            <EditTwoTone color="primary" />
        </IconButton>
    )

    
    return (
        <div>
            <CustomTable
                rows={events}
                loading={loading}
                headCells={headCells}
                ActionButton={EditButton}
                ImagePreview={ImagePreview}

            ></CustomTable>
        </div>
    );
}

export default EventList;
