import * as React from "react";
import { useState } from "react";
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { TextField, Typography, Button, Snackbar, Alert } from "@mui/material";
import axios from "../../../api/axios";
import * as XLSX from "xlsx";
function OderListCustomDate() {
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    async function oderListPerMonth() {

        try {
            setLoading(true);
            const response = await axios.post("get_orders_in_date_range/", {
                start_date: dayjs(startDate).format('YYYY-MM-DD'),
                end_date: dayjs(endDate).format('YYYY-MM-DD'),
            });
            if (response.status === 200) {
                

                const worksheet = XLSX.utils.json_to_sheet(response.data["data"]);

                // Create a new workbook, add the worksheet to it
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "data");

                // Write the workbook to a file and download it
                // make the name of the file based on the query params

                XLSX.writeFile(workbook, dayjs(startDate).format('YYYY_MM_DD') +" to " + dayjs(endDate).format('YYYY_MM_DD') + "_orders.xlsx");




            }
            else {
                console.log(response.data.msg);
                setAlertOpen(true);
                setAlertMessage(response.data.msg);
                setAlertSeverity("error");
            }

        } catch (error) {
            console.error(error);
            setAlertOpen(true);
            setAlertMessage(error);
            setAlertSeverity("error");
        }
        finally {
            setLoading(false);
        }
    }
    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertOpen(false);
    };

    const body = (
        <div>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Typography variant="h6">
                Download Item order count for custom date range:
            </Typography>


            <LocalizationProvider
                dateAdapter={AdapterDateFns}>
                <DatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    label="Start Date"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    fullWidth
                    renderInput={(props) => <TextField sx={{ mt: 2, width: 220 }}{...props} fullWidth />}
                />
            </LocalizationProvider>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}>
                <DatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    label="End Date"
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    fullWidth
                    renderInput={(props) => <TextField sx={{ mt: 2, width: 220 }}{...props} fullWidth />}
                />
            </LocalizationProvider>
            <div>

            </div>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => {
                    oderListPerMonth();
                }}
                disabled={loading}
            >
                Download
            </Button>
        </div>
    );
    return body;

}

export default OderListCustomDate;