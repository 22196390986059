import React, { useEffect } from 'react'
import axios from '../../../api/axios';
import DataCard from '../../../components/dataCard';

function ActivePackageCard() {
    const [activePackage, setActivePackage] = React.useState(0);
    const [activePackageText, setActivePackageText] = React.useState('');
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(
                "/package/count/active/",
                {
                    headers: {
                        "Content-Type": "application/json",
                    },

                }
            );
            setActivePackage(response.data.total);
            setActivePackageText('LS: ' + response.data.lifestyle + ', BD: ' + response.data.bodybuilding + ', SC: ' + response.data.scale + ', LB: ' + response.data.lunchbox + ', 1100: ' + response.data['1100Calories'] + ', 1250: '+ response.data['1250Calories']);
        };
        try {
            fetchData();
        } catch (error) {
            console.log(error);
        }


    }, []);

    return (
        <DataCard title={"Active Packages"} data={activePackage} footer={activePackageText}></DataCard>
    )
}

export default ActivePackageCard