import { Grid } from "@mui/material";
import React from "react";
import Layout from "../../layout/layout";
import ActivePackageCard from "./parts/activePackageCard";
import NewUserChart from "./parts/newUserChart";
import PackagePerMonth from "./parts/packagePerMonth";
import TotalUserCard from "./parts/totalUserCard";

function Dashboard() {
  const body = (
    <div>
      <Grid container spacing={10}>
        <Grid item xs={12} md={3}><TotalUserCard /></Grid>
        <Grid item xs={12} md={3}><ActivePackageCard /></Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12} md={12} sx={{
          height: 400
        }}><NewUserChart /></Grid>
      </Grid>
      <Grid container sx={{ pb: 10 }}>
        <Grid item xs={12} md={12} sx={{
          height: 400
        }}><PackagePerMonth /></Grid>
      </Grid>
    </div>
  );
  return <Layout body={body} title={"Dashboard"} />;

}

export default Dashboard;
