import * as React from "react";
import { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import axios from "../../api/axios";
import {Button, Grid, Card } from "@mui/material";


import { Link, useNavigate } from 'react-router-dom';

const EventSelectionPage = () => {
    const [eventList, setEventList] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchEvents = async () => {
            const response = await axios.get("event/list/"); // replace with your API endpoint
            setEventList(response.data);
        };

        fetchEvents();
        
    }, []);

    
    
    
    const body = (
        <div>
            <h1>Select Event</h1>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Card>
                        <Link to="/buy-subscription">
                            <Button variant="contained" color="primary" size="large" fullWidth>
                                Regular Package
                            </Button>
                        </Link>
                    </Card>
                </Grid>

                {eventList.map((event) => {
                    if (!event.is_expired) {
                        return (
                            <Grid item xs={6} key={event.id}>
                                <Card>
                                    
                                        <Button variant="contained" color="primary" size="large" onClick={() => navigate('/buy-event-subscription', { state: { event: event } })} fullWidth>
                                            {event.name}
                                        </Button>
                                    
                                </Card>
                            </Grid>
                        );
                    }
                    return null;
                })}
            </Grid>
        </div>
    );
    return <Layout body={body} title={"Select Event"} />;
};

export default EventSelectionPage;