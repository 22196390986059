import { Paper, Typography } from '@mui/material';
import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from '../../../api/axios';

function NewUserChart() {
    const [userVSMonth, setUserVSMonth] = React.useState([]);
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(
                "/user/count/month/",
                
            );
            setUserVSMonth(response.data.data);
        };


        try {
            fetchData();
        } catch (error) {
            console.log(error);
        }
    }, []);
    return (
        <>
            <Paper sx={{ mt:2, height:500, p:2}}>
                <Typography variant='h6' color="primary" sx={{ mb: 2 }}> New user per month</Typography>
                <ResponsiveContainer width="100%" height="90%">
                    <LineChart
                        width={500}
                        height={300}
                        data={userVSMonth}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis  />
                        <Tooltip  />
                        <Legend />
                        {/* <Line type="monotone" dataKey="count" stroke="#8884d8"  /> */}
                        <Line type="monotone" dataKey="count" stroke="#82ca9d" activeDot={{ r: 8 }}/>
                    </LineChart>
                </ResponsiveContainer>
            </Paper>
        </>
    )
}

export default NewUserChart