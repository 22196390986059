import * as React from "react";
import { useState } from "react";
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { TextField, Typography, Button, Snackbar, Alert } from "@mui/material";
import axios from "../../../api/axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
function DriverListDownload() {
    const [date, setDate] = useState(dayjs());
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    async function downloadDriverList() {

        try {
            setLoading(true);
            console.log(dayjs(date).format('YYYY-MM-DD'));
            const response = await axios.post("get_order_list/driver/", {
                date: dayjs(date).format('YYYY-MM-DD'),
            });
            if (response.status === 200) {
                const fileType =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";
                var workbook = XLSX.utils.book_new();
                for (let i = 0; i < response.data.length; i++) {
                    console.log(response.data[i].driver);
                    var worksheet = XLSX.utils.aoa_to_sheet([
                        [" ", "Driver number:", response.data[i].driver],
                        [],
                        [" ", "Driver name:"],
                        [" "]]);
                    XLSX.utils.sheet_add_json(
                        worksheet,
                        response.data[i].orders,
                        { origin: -1 });
                    XLSX.utils.book_append_sheet(
                        workbook,
                        worksheet,
                        "Driver " + response.data[i].driver);

                }


                const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, "Driver list " + dayjs(date).format('DD-MM-YYYY') + fileExtension);
            }
            else {
                console.log(response.data.msg);
                setAlertOpen(true);
                setAlertMessage(response.data.msg);
                setAlertSeverity("error");
            }

        } catch (error) {
            console.error(error);
            setAlertOpen(true);
            setAlertMessage(error);
            setAlertSeverity("error");
        }
        finally {
            setLoading(false);
        }
    }
    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertOpen(false);
    };

    const body = (
        <div>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Typography variant="h6">
                Download Driver List:
            </Typography>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}>
                <DatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    label="Date"
                    value={date}
                    onChange={(date) => setDate(date)}
                    fullWidth
                    renderInput={(props) => <TextField sx={{ mt: 2, width: 220 }}{...props} fullWidth />}
                />
            </LocalizationProvider>
            <div>

            </div>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => {
                    downloadDriverList();
                }}
                disabled={loading}
            >
                Download
            </Button>
        </div>
    );
    return body;

}

export default DriverListDownload;