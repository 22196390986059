import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { TextField, Typography, Button, Snackbar, Alert, Autocomplete } from "@mui/material";
import axios from "../../../api/axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
function OrderListByDish() {
    const [date, setDate] = useState(dayjs());
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [loading, setLoading] = useState(true);
    const [dishList, setDishList] = useState([]);
    const [selectedDish, setSelectedDish] = useState(null);

    useEffect(() => {
        const fetchDishList = async () => {
            try {
                const response = await axios.get("/dishes/");
                console.log(response.data);
                setDishList(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching dish list:", error);
                setAlertMessage("Failed to fetch dish list");
                setAlertSeverity("error");
                setAlertOpen(true);
            }
        };

        fetchDishList();
    }, []);

    async function downloadDriverList() {

        try {
            setLoading(true);
            console.log(dayjs(date).format('YYYY-MM-DD'));
            const response = await axios.post("order/dish/", {
                date: dayjs(date).format('YYYY-MM-DD'),
                dish_id: selectedDish.id
            });
            if (response.status === 200) {
                                console.log(response.data);
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";
                var workbook = XLSX.utils.book_new();
                
                // Extract the data from the response
                const dishData = response.data.data;
                
                // Create a worksheet for the dish
                var worksheet = XLSX.utils.aoa_to_sheet([
                    ["Dish ID:", dishData.dish_id],
                    ["Dish Name:", dishData.dish_name],
                    ["Date:", dishData.date],
                    ["Total Orders:", dishData.total_orders],
                    [],
                    ["Order ID", "User ID", "User Name", "User Phone", "Dish Name", "Date"]
                ]);
                
                // Add the orders to the worksheet
                XLSX.utils.sheet_add_json(
                    worksheet,
                    dishData.orders,
                    { origin: -1, skipHeader: true }
                );
                
                // Append the worksheet to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheet, "Dish " + dishData.dish_id);
                
                // Write the workbook to a file
                const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, "Dish Orders " + dayjs(date).format('DD-MM-YYYY') + fileExtension);
            }
            else {
                console.log(response.data.msg);
                setAlertOpen(true);
                setAlertMessage(response.data.msg);
                setAlertSeverity("error");
            }

        } catch (error) {
            console.error(error);
            setAlertOpen(true);
            setAlertMessage(error);
            setAlertSeverity("error");
        }
        finally {
            setLoading(false);
        }
    }
    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertOpen(false);
    };

    const body = (
        <div>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Typography variant="h6">
                Download Dish Order:
            </Typography>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={dishList}
                sx={{ width: 600 }}
                getOptionLabel={(option) => option.name} // Specify how to get the label from each option
                renderInput={(params) => (
                    <TextField {...params} label="Dish" disabled={loading} />
                )}
                getOptionSelected={(option, value) => option.id === value.id} // Ensure unique selection
                key={(option) => option.id}
                onChange={(event, newValue) => setSelectedDish(newValue)}
            />
            <LocalizationProvider
                dateAdapter={AdapterDateFns}>
                <DatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    label="Date"
                    value={date}
                    onChange={(date) => setDate(date)}
                    fullWidth
                    renderInput={(props) => <TextField sx={{ mt: 2, width: 220 }}{...props} fullWidth />}
                />
            </LocalizationProvider>
            <div>

            </div>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => {
                    downloadDriverList();
                }}
                disabled={loading}
            >
                Download
            </Button>
        </div>
    );
    return body;

}

export default OrderListByDish;