import { Button } from "@mui/material";
import Layout from "../../../layout/layout";
import { useNavigate } from "react-router-dom";
import EventDishCategoryList from "./category-list";
import {  ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';

function EventDishCategoriesPage() {
    const navigate = useNavigate();
    const body = (
        <div>
            <Button variant="contained" component={Link} to="/events"
                sx={{ mb: 2 }}><ArrowBack /> Back to Event </Button>
            <Button variant="contained" color="primary" sx={{ mr: 2, mb: 2, ml: 2}}
                onClick={() => {
                    navigate("/create-event-dish-category", { replace: true });
                }}>
                Create Category
            </Button>
            <EventDishCategoryList />
            
        </div>
    );
    return <Layout body={body} title={"Categories"} />;
}

export default EventDishCategoriesPage;