
import * as React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Layout from '../../../layout/layout';
import { TextField, Select, MenuItem, InputLabel, FormControl, Button, Box, Typography, Grid, FormControlLabel, Switch } from '@mui/material';
import { PhotoCamera, ArrowBack } from '@mui/icons-material';
import axios from "../../../api/axios";

export default function CreateEventDish() {
    const location = useLocation();
    const navigate = useNavigate();
    const [dishId, setDishId] = React.useState(null);
    const [dishName, setDishName] = React.useState('');
    const [dishCategory, setDishCategory] = React.useState('');
    const [details, setDetails] = React.useState('');
    const [proteins, setProteins] = React.useState(null);
    const [fats, setFats] = React.useState(null);
    const [calories, setCalories] = React.useState(null);
    const [carbs, setCarbs] = React.useState(null);
    const [photo, setPhoto] = React.useState(null);
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [eventId, setEventId] = React.useState(null);
    const [dishCategoryList, setDishCategoryList] = React.useState([]);
    const [active, setActive] = React.useState(true);
    const [isPhotoChanged, setIsPhotoChanged] = React.useState(false);
    const [photoPreview, setPhotoPreview] = React.useState(null);

    const handleDishNameChange = (event) => {
        setDishName(event.target.value);
    };

    const handleDishCategoryChange = (event) => {
        setDishCategory(event.target.value);
    };

    const handleDetailsChange = (event) => {
        setDetails(event.target.value);
    };


    const handleProteinsChange = (event) => {
        setProteins(event.target.value);
    };

    const handleFatsChange = (event) => {
        setFats(event.target.value);
    };

    const handleCaloriesChange = (event) => {
        setCalories(event.target.value);
    };

    const handleCarbsChange = (event) => {
        setCarbs(event.target.value);
    };

    const handlePhotoChange = (event) => {
        setPhoto(event.target.files[0]);
        setPhotoPreview(URL.createObjectURL(event.target.files[0]));
        setIsPhotoChanged(true);
    };
    const handleActiveChange = (event) => {
        setActive(event.target.checked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let dishData = new FormData();
        dishData.append('event_dish_id', dishId);
        dishData.append('name', dishName);
        dishData.append('event_dish_category_id', dishCategory);
        dishData.append('details', details);
        dishData.append('proteins', proteins);
        dishData.append('fats', fats);
        dishData.append('calories', calories);
        dishData.append('carbs', carbs);
        dishData.append('active', active ? 1 : 0);
        dishData.append('event_id', eventId);
        if (isPhotoChanged) {
            dishData.append('photo', photo);
        }
        else {
            dishData.append('photo', null);
        }
        if (isEditMode) {
            axios.put("event/dish/update/", dishData).then((response) => {
                if (response.status === 200) {
                    alert("Dish updated successfully");
                }
                else {
                    alert("Something went wrong");
                }
            });
        } else {
            axios.post("event/dish/create/", dishData).then((response) => {

                if (response.status === 201) {
                    alert("Dish created successfully");
                }
                else {
                    console.log(response);

                }
            });
        }
    };
    const isFormValid = () => {
        return dishName && dishCategory && photo;
    }

    React.useEffect(() => {
        const dish = location.state.dish;
        setEventId(location.state.event.id);
        try {
            axios.post("event/dish_category/get-by-event-id/", { "event_id": location.state.event.id }).then((response) => {
                if (response.status === 200) {
                    setDishCategoryList(response.data);
                }
            });
        } catch (error) {
            console.log(error);
        }
        if (dish) {
            setIsEditMode(true);
            setDishId(dish.id);
            setDishName(dish.name);
            setDishCategory(dish.event_dish_category.id);
            setDetails(dish.details);
            setProteins(dish.proteins);
            setFats(dish.fats);
            setCalories(dish.calories);
            setCarbs(dish.carbs);
            setActive(dish.active);
            setPhoto(dish.photo);
            setPhotoPreview(process.env.REACT_APP_MEDIA_URL + dish.photo);
        }
    }, [location]);




    const body = (
        <Box sx={{ flexGrow: 1 }}>
            <Button variant='contained' onClick={() => navigate('/event-dishes/', { state: { event: location.state.event } })} sx={{ mb: 2, ml: 2 }} ><ArrowBack /> Back to {location.state.event.name} dish list</Button>


            <Typography variant="h4" gutterBottom>
                {isEditMode ? 'Edit ' : 'Create '}
                Event Dish
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="dish-category-label">Dish Category</InputLabel>
                            <Select
                                labelId="dish-category-label"
                                id="dish-category"
                                value={dishCategory}
                                onChange={handleDishCategoryChange}
                            >
                                {dishCategoryList.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="dish-name"
                                label="Dish Name"
                                value={dishName}
                                onChange={handleDishNameChange}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="details"
                                label="Details"
                                multiline
                                rows={2}
                                value={details}
                                onChange={handleDetailsChange}
                            />
                        </FormControl>
                        {/* <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="tags-label">Tags</InputLabel>
                            <Select
                                labelId="tags-label"
                                id="tags"
                                multiple
                                value={tags}
                                onChange={handleTagsChange}

                            >
                                {tagList.map((tag) => (
                                    <MenuItem key={tag.id} value={tag.name}>
                                        {tag.name}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl> */}
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="proteins"
                                label="Proteins (g)"
                                value={proteins}
                                onChange={handleProteinsChange}
                                type="number"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="carbs"
                                label="Carbs (g)"
                                value={carbs}
                                onChange={handleCarbsChange}
                                type="number"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <FormControlLabel control={<Switch checked={active} onChange={handleActiveChange} name="is_active" />} label="Active" />
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ height: '240px', position: 'relative' }}>
                            {photo && (
                                <img
                                    src={photoPreview}
                                    alt="Dish"
                                    style={{
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            )}
                        </Box>
                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={<PhotoCamera />}

                            >
                                Upload Photo
                                <input
                                    type="file"
                                    hidden
                                    onChange={handlePhotoChange}
                                />
                            </Button>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="fats"
                                label="Fats (g)"
                                value={fats}
                                onChange={handleFatsChange}
                                type="number"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                id="calories"
                                label="Calories"
                                value={calories}
                                onChange={handleCaloriesChange}
                                type="number"
                            />
                        </FormControl>

                    </Grid>
                </Grid>
                <Box sx={{ mt: 2 }}>
                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={!isFormValid()}
                        onClick={handleSubmit}
                    >
                        {isEditMode ? 'Update' : 'Create'}
                    </Button>
                </Box>
            </form>
        </Box>
    );

    return (
        <Layout body={body} location={location} />
    );
}